// Polyfills first
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "url-polyfill";
import "abortcontroller-polyfill";

import "react-scan";
import { Suspense } from "react";
import { render } from "react-dom";

import { addOneTrustScripts } from "@hotel-engine/scripts/ThirdPartyScripts/addOneTrustScripts";

// Any additional "Side Effect Imports" go here
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-phone-input-2/lib/style.css";
import "react-spring-bottom-sheet/dist/style.css";

import { RouteLoadingUI } from "./RouteLoadingUI";
import Providers from "./Providers";

// Disables the cookie prompt when website is being used as a seatmap portal
// inside of a RN Webview
if (globalThis.location.pathname !== "/flights/seatmap-portal") {
  addOneTrustScripts();
}

render(
  <Suspense fallback={<RouteLoadingUI />}>
    <Providers />
  </Suspense>,
  document.getElementById("root")
);
